import OSS from "ali-oss";
import { Client } from "minio";

import { getOssSts, setOssRecord } from "@/api/tool/oss";

export default (conf) => {
  return new OSS(conf);
};

String.prototype.format = function (obj) {
  var formatted = this;

  Object.keys(obj).forEach((key) => {
    var reg = new RegExp("\\{" + key + "\\}", "s");
    formatted = formatted.replace(reg, obj[key]);
  });
  return formatted;
};

export function aliOSSUpload(isFillUpload = false, uploadConfig = {}) {
  let ossClient = null;
  const files = null;
  const parallel = 2;
  const partSize = 1024 * 512; // 每个分片大小(byte)
  const path = "";
  const region = "oss-cn-beijing";

  let uploadCheckPoint = null; // 上传进度记录

  let stsConfig = {};

  let uploadFile = null;

  uploadConfig = {
    fillId: "fillId",
    formId: "formId",
    agencyId: 0,
    userId: "userId",
    ossInfo: {},
    uploadPath: null,
    uploadSuccess: null,
    uploadError: null,
    uploadProgress: null,
    ...uploadConfig,
  };

  // 初始化ossClient
  const initOSSClient = async () => {
    stsConfig = await getOssSts(isFillUpload, uploadConfig.agencyId);
    if (ossClient) {
      return;
    } else {
      console.log("occClient 未初始化", ossClient);
    }

    if (stsConfig.data.type === "ali") {
      ossClient = new OSS({
        region: region,
        accessKeyId: stsConfig.data.accessKeyId,
        accessKeySecret: stsConfig.data.accessKeySecret,
        stsToken: stsConfig.data.securityToken,
        bucket: stsConfig.data.bucketName,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          const sts = await getOssSts(false, uploadConfig.agencyId);
          return {
            accessKeyId: sts.data.accessKeyId,
            accessKeySecret: sts.data.accessKeySecret,
            stsToken: sts.data.stsToken,
          };
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 18 * 60 * 1000,
      });
    } else if (stsConfig.data.type === "minio") {
      ossClient = new Client({
        endPoint: stsConfig.data.stsEndpoint,
        port: stsConfig.data.port,
        useSSL: stsConfig.data.useSSL,
        accessKey: stsConfig.data.accessKeyId,
        secretKey: stsConfig.data.accessKeySecret,
        sessionToken: stsConfig.data.securityToken,
      });
    }
    uploadConfig.ossInfo = stsConfig.data;
    console.log("初始化了新OSSClient");
  };

  const upload = async (fileObj) => {
    uploadFile = fileObj;
    let result;
    try {
      if (uploadFile.size < partSize) {
        result = await commonUpload();
      } else {
        result = await multipartUpload();
      }
    } catch (e) {
      if (uploadConfig.uploadError) uploadConfig.uploadError(e);
    }
    if (result) {
      setOssRecord({
        originalFileName: uploadFile.name,
        fileName:
          uploadConfig.uploadPath.format(uploadConfig) + uploadFile.name,
        fileSize: uploadFile.size,
        fileUrl: result.url,
        folder: uploadConfig.uploadPath.format(uploadConfig),
        belongId: uploadConfig.formId,
      }).then((res) => {
        result.fileId = res.data.fileId;
        if (uploadConfig.uploadSuccess) {
          uploadConfig.uploadSuccess(result, res.data.fileId);
        }
      });
    }
  };

  const stop = () => {
    if (ossClient) ossClient.cancel();
  };
  const resume = () => {
    if (uploadCheckPoint) upload(uploadFile);
  };

  // 普通上传
  const commonUpload = async () => {
    if (!ossClient) {
      await initOSSClient();
    }
    const reFileName = generateFileName(uploadFile);
    const uploadPath = uploadConfig.uploadPath.format(uploadConfig);
    return new Promise((resolve, reject) => {
      if (uploadConfig.ossInfo.type === "ali") {
        ossClient
          .put(
            `${uploadPath}${uploadPath.endsWith("/") ? "" : "/"}${reFileName}`,
            uploadFile.raw
          )
          .then((result) => {
            console.log("普通文件上传", result);
            uploadFile.percent = 100;
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      } else if (uploadConfig.ossInfo.type === "minio") {
        const reader = new FileReader();
        reader.readAsArrayBuffer(uploadFile.raw);
        reader.onload = function (e) {
          const res = e.target.result;
          const buf = Buffer.from(res);
          const fileName = `${uploadPath}${
            uploadPath.endsWith("/") ? "" : "/"
          }${reFileName}`;
          ossClient.putObject(
            uploadConfig.ossInfo.bucketName,
            fileName,
            buf,
            (err, result) => {
              if (err == null) {
                var result = {};
                result.url =
                  uploadConfig.ossInfo.transformEndpoint +
                  "/" +
                  uploadConfig.ossInfo.bucketName +
                  "/" +
                  fileName;

                uploadCheckPoint = null;
                uploadFile.percent = 100;
                resolve(result);
              } else {
                reject(err);
              }
            }
          );
        };
        console.log("MINIO普通文件上传");
      }
    });
  };

  //miniO 上传进度 https://juejin.cn/post/7075616880571973646
  // 分片上传
  const multipartUpload = async () => {
    if (!ossClient) {
      await initOSSClient();
    }
    const reFileName = generateFileName(uploadFile);

    const uploadPath = uploadConfig.uploadPath.format(uploadConfig);
    const uploadPathFix = `${uploadPath}${uploadPath.endsWith("/") ? "" : "/"}`;
    const fileName = `${uploadPathFix}${reFileName}`;

    if (uploadCheckPoint) {
      return resumeMultipartUpload(uploadCheckPoint);
    }

    return new Promise((resolve, reject) => {
      if (uploadConfig.ossInfo.type === "ali") {
        ossClient
          .multipartUpload(fileName, uploadFile.raw, {
            parallel: parallel,
            partSize: partSize,
            progress: (progress, checkpoint) => {
              onMultipartUploadProgress(progress, checkpoint);
              if (uploadConfig.uploadProgress) {
                uploadConfig.uploadProgress(progress, checkpoint);
              }
            },
          })
          .then((result) => {
            console.log("分片上传上传", result);
            // 生成文件下载地址
            // const url = `https://${stsConfig.data.bucketName}.${region}.aliyuncs.com/${uploadPathFix}${encodeURIComponent(fileName)}`;

            const url = getUploadFileUrl(result.res.requestUrls);

            // const url=result.url;
            uploadCheckPoint = null;

            result.url = url;
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      } else if (uploadConfig.ossInfo.type === "minio") {
        const reader = new FileReader();
        reader.readAsArrayBuffer(uploadFile.raw);
        reader.onload = function (e) {
          const res = e.target.result;
          const buf = Buffer.from(res);
          ossClient.putObject(
            uploadConfig.ossInfo.bucketName,
            fileName,
            buf,
            (err, result) => {
              if (err == null) {
                var result = {};
                result.url =
                  uploadConfig.ossInfo.transformEndpoint +
                  "/" +
                  uploadConfig.ossInfo.bucketName +
                  "/" +
                  fileName;

                uploadCheckPoint = null;
                uploadFile.percent = 100;
                resolve(result);
              } else {
                reject(err);
              }
            }
          );
        };
      }
    });
  };

  // 分片上传进度改变回调
  const onMultipartUploadProgress = (progress, checkpoint) => {
    uploadCheckPoint = checkpoint;
  };

  // 断点续传
  const resumeMultipartUpload = async () => {
    const fileName = uploadFile.raw.name;
    const uploadPath = uploadConfig.uploadPath.format(uploadConfig);
    const uploadPathFix = `${uploadPath}${uploadPath.endsWith("/") ? "" : "/"}`;

    return new Promise((resolve, reject) => {
      const { uploadId, file, name } = uploadCheckPoint;
      if (uploadConfig.ossInfo.type === "ali") {
        ossClient
          .multipartUpload(uploadId, file, {
            parallel: parallel,
            partSize: partSize,
            progress: (progress, checkpoint) => {
              onMultipartUploadProgress(progress, checkpoint);
              if (uploadConfig.uploadProgress) {
                uploadConfig.uploadProgress(progress, checkpoint);
              }
            },
            checkpoint: uploadCheckPoint,
          })
          .then((result) => {
            console.log("断点续传", result);
            // uploadCheckPoint=null;
            // const url = `https://${stsConfig.data.bucketName}.${region}.aliyuncs.com/${uploadPathFix}${encodeURIComponent(file.name)}`;
            // console.log(`Resume multipart upload ${file.name} succeeded, url === `,url);

            const url = getUploadFileUrl(result.res.requestUrls);
            result.url = url;
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      } else if (uploadConfig.ossInfo.type === "minio") {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
          const res = e.target.result;
          const buf = Buffer.from(res);
          ossClient.putObject(
            uploadConfig.ossInfo.bucketName,
            fileName,
            buf,
            (err, result) => {
              if (err == null) {
                var result = {};
                result.url =
                  uploadConfig.ossInfo.transformEndpoint +
                  "/" +
                  uploadConfig.ossInfo.bucketName +
                  "/" +
                  fileName;
                resolve(result);
                uploadFile.percent = 100;
              } else {
                reject(err);
              }
            }
          );
        };
      }
    });
  };

  const getSTSConfig = async () => {
    if (!ossClient) {
      await initOSSClient();
    }
    return { ...stsConfig.data, region: region };
  };

  const getUploadFileUrl = (responseUrl) => {
    if (!Array.isArray(responseUrl)) {
      return responseUrl;
    }
    const url = responseUrl[0];

    if (url.indexOf("?uploadId") >= 0) {
      return url.substring(0, url.indexOf("?uploadId"));
    } else {
      return url;
    }
  };

  const generateFileName = (selectedFile) => {
    let fileName = "";
    if (uploadConfig.fixedName) {
      fileName = selectedFile.name;
    } else {
      let extension = "";
      if (selectedFile.name.indexOf(".") >= 0) {
        extension = selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".")
        );
      }
      fileName =
        (selectedFile.uid || new Date().valueOf() + selectedFile.size) +
        extension;
    }

    return fileName;
  };

  return {
    initOSSClient,
    upload,
    stop,
    resume,
    commonUpload,
    multipartUpload,
    getSTSConfig,
    setUploadConfig: () => {
      console.log("setUploadConfig is Obsolete ,Use Config Prop");
    },

    get config() {
      return uploadConfig;
    },
    set config(val) {
      Object.keys(val).forEach((key) => {
        uploadConfig[key] = val[key];
      });
    },
  };
}

export function aliUploader(
  isFillUpload = false,
  uploadConfig = {},
  fileListProvider
) {
  let fileList = [];
  let _config = {};
  const _uploadConfig = uploadConfig;
  // element-ui 文件上传
  const elFileUpload = (selectedFile) => {
    _config = { ..._config, ..._uploadConfig };

    // 上传时再获取文件列表，防止文件列表获取后变量的指向又发生改变
    if (fileListProvider) fileList = fileListProvider();

    const uploadHandler = new Promise(async (resolve, reject) => {
      const myOssUpload = aliOSSUpload(isFillUpload, _config);
      await myOssUpload.initOSSClient();

      const file = {
        uid:
          selectedFile.file.uid ||
          new Date().valueOf() + selectedFile.file.size,
        lastModified: selectedFile.file.lastModified,
        name: selectedFile.file.name,
        url: "",
        size: selectedFile.file.size,
        sort: fileList.length + 1,
        percent: 0,
        raw: selectedFile.file,
        stop: myOssUpload.stop,
        resume: myOssUpload.resume,
      };

      fileList.push(file);

      if (_config.uploadFileSelectedHandler) {
        _config.uploadFileSelectedHandler(file, fileList);
      }

      if (!myOssUpload.config.agencyId) {
        console.error("未找到agencyId");
      }
      if (!myOssUpload.config.uploadPath) {
        console.error("未定义uploadPath");
      }

      (myOssUpload.config.uploadSuccess = (uploadResult, stateResult) => {
        file.url = uploadResult.url;
        file.fileId = stateResult;
        delete file.raw;
        delete file.status;
        delete file.percentage;
        delete file.percent;
        delete file.progress;
        delete file.response;
        delete file.stop;
        delete file.resume;

        if (_config.uploadSuccessHandler) {
          _config.uploadSuccessHandler(file, _config.fileList);
        }
        resolve(file.url);
      }),
        (myOssUpload.config.uploadProgress = (progress) => {
          file.percent = (progress * 100).toFixed(0);
          if (_config.uploadProgressHandler) {
            _config.uploadProgressHandler(file.percent);
          }
        }),
        (myOssUpload.config.uploadError = (exceptionResult) => {
          reject(exceptionResult);
        });
      myOssUpload.upload(file);
    });
    uploadHandler.abort = (file) => {
      stop(file);
    };
    return uploadHandler;
  };

  // element-ui 文件上传
  const elPictureUpload = (selectedFile) => {
    _config = { ..._config, ..._uploadConfig };

    // 上传时再获取文件列表，防止文件列表获取后变量的指向又发生改变
    if (fileListProvider) fileList = fileListProvider();

    const uploadHandler = new Promise(async (uploadHandlerResolve, reject) => {
      const myOssUpload = aliOSSUpload(isFillUpload, _config);
      const myOssThumbUpload = aliOSSUpload(isFillUpload, _config);
      await myOssUpload.initOSSClient();
      await myOssThumbUpload.initOSSClient();

      const file = {
        uid:
          selectedFile.file.uid ||
          new Date().valueOf() + selectedFile.file.size,
        lastModified: selectedFile.file.lastModified,
        name: selectedFile.file.name,
        url: "",
        thumb: "",
        size: selectedFile.file.size,
        sort: fileList.length + 1,
        percent: 0,
        raw: selectedFile.file,
        mediaType: selectedFile.file.type,

        stop: myOssUpload.stop,
        resume: myOssUpload.resume,
      };
      await ratioPicture(file);
      await thumbPicture(file);
      fileList.push(file);

      if (_config.uploadFileSelectedHandler) {
        _config.uploadFileSelectedHandler(file, fileList);
      }

      if (!myOssUpload.config.agencyId) {
        console.error("未找到agencyId");
      }
      if (!myOssUpload.config.uploadPath) {
        console.error("未定义uploadPath");
      }

      const uploadThumb = () => {
        return new Promise((resolve) => {
          myOssThumbUpload.config.uploadSuccess = (
            uploadResult,
            stateResult
          ) => {
            file.thumbFileId = stateResult;
            file.thumb = uploadResult.url;
            resolve(file);
          };
          myOssThumbUpload.config.uploadError = (exceptionResult) => {
            reject(exceptionResult);
          };

          const fileData = base64ToFile(file.thumb, `thumb_${file.name}`);
          myOssThumbUpload.upload({
            raw: fileData,
            name: `thumb_${file.name.replace(/\.mp4|\.flv|\.avi|\.wmv|\.mov/,'.png')}`,
            size: fileData.size,
          });
        });
      };

      const uploadPicture = (thumbFileId) => {
        return new Promise((resolve) => {
          (myOssUpload.config.uploadSuccess = (uploadResult, stateResult) => {
            file.url = uploadResult.url;
            file.fileId = stateResult;
            file.thumbFileId = thumbFileId;
            delete file.raw;
            delete file.status;
            delete file.lastModified;
            delete file.percentage;
            delete file.percent;
            delete file.progress;
            delete file.response;
            delete file.stop;
            delete file.resume;

            if (_config.uploadSuccessHandler) {
              _config.uploadSuccessHandler(file, fileList);
            }

            resolve(file);
          }),
            (myOssUpload.config.uploadProgress = (progress) => {
              file.percent = (progress * 100).toFixed(0);

              if (_config.uploadProgressHandler) {
                _config.uploadProgressHandler(file.percent);
              }
            }),
            (myOssUpload.config.uploadError = (exceptionResult) => {
              reject(exceptionResult);
            });
          myOssUpload.upload(file);
        });
      };

      Promise.resolve()
        .then(uploadThumb)
        .then((uploadResult) => {
          return new Promise((resolve) => {
            resolve(uploadResult.thumbFileId);
          });
        })
        .then(uploadPicture)
        .then((uploadResult) => {
          delete file.raw;
          delete file.status;
          delete file.percentage;
          delete file.progress;
          delete file.response;

          uploadHandlerResolve(file.url);
        });
    });
    uploadHandler.abort = (file) => {
      stop(file);
    };
    return uploadHandler;
  };

  const ratioPicture = (file) => {
    if (file.mediaType.indexOf("video") >= 0) {
      return Promise.resolve();
    }
    return new Promise(function (resolve) {
      const _URL = window.URL || window.webkitURL;
      const img = new Image();

      img.onload = function () {
        resolve({
          width: img.width,
          height: img.height,
          ratio: countRatio(img.width, img.height),
        });
      };
      img.src = _URL.createObjectURL(file.raw);
      file.url = img.src;
    });
  };

  const countRatio = (width, height) => {
    var min = Math.min(width, height);
    var max = Math.max(width, height);
    var t = 0;
    // for循环求最大公约数 --- （ 最小公倍数 ＝ a * b / 最大公约数 ）
    for (var i = min; i > 0; i--) {
      if (width % i == 0 && height % i == 0) {
        t = i;
        break;
      }
    }
    return [width / t, height / t];
  };

  const thumbPicture = (file) => {
    if (file.mediaType.indexOf("video") >= 0) {
      // 获取图片预览图
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL;
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
          var video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = async function () {
            let canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            var ctx = canvas.getContext("2d");
            video.currentTime = 1; // 设置视频播放到第几秒，这里设置为1秒
            video.onseeked = function () {
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              // 如果需要，可以在这里将canvas转换为图片
              var dataURL = canvas.toDataURL("image/png");
              file.thumb = dataURL;
              file.width = canvas.width;
              file.height = canvas.height;
              file.ratio = countRatio(canvas.width, canvas.height);

              _URL.revokeObjectURL(video.src);
              resolve(file.thumb);
              // 如果需要下载图片，可以使用以下代码：
              // var a = document.createElement('a');
              // a.href = dataURL;
              // a.download = 'screenshot.png';
              // a.click();
            };

            // 播放到当前时间的帧，才能截取到当前的画面
            await video.play();
            await video.pause();
          };
          video.src = URL.createObjectURL(file.raw);
        };
        fileReader.readAsArrayBuffer(file.raw);
      });
    } else {
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();
        image.crossOrigin = "Anonymous";

        let width = 0;
        let height = 0;

        function draw() {
          let ratio = 0;
          width = image.width;
          height = image.height;

          if (image.width > 1000 || image.height > 1200) {
            ratio = parseFloat(image.width) / parseFloat(image.height);
            if (image.width > image.height) {
              width = 600;
              height = parseFloat(width) / ratio;
            } else {
              height = 800;
              width = parseFloat(height) * ratio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);
          file.thumb = canvas.toDataURL();
          file.url = image.src;
          (file.width = image.width),
            (file.height = image.height),
            (file.ratio = countRatio(image.width, image.height)),
            resolve(file.thumb);
        }
        image.onload = draw;
        image.src = _URL.createObjectURL(file.raw);
      });
    }
  };

  const base64ToFile = (pictureBase64, fileName) => {
    var arr = pictureBase64.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });

    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  };

  return {
    elFileUpload,
    elPictureUpload,
    get $this() {
      return this;
    },
    set list(val) {
      _config.fileList = val;
    },

    get config() {
      return _config;
    },
    set config(val) {
      Object.keys(val).forEach((key) => {
        _config[key] = val[key];
      });
    },
  };
}
