import request from '@/utils/request'

// 获取OSS临时Token
export function getOssSts(isFillUpload, agencyId) {
  return request({
    url: '/oss/endpoint/sts',
    params: {
      isFillUpload,
      agencyId
    },
    method: 'get'
  })
}

// 阿里云授权接口
// export function getOssSts(isFillUpload,formId) {
//   return request({
//     url: `/ali/sts?isFillUpload=${(isFillUpload||false)}&formId=${formId||''}`,
//     method: 'post',
//   })
// }

export function setOssRecord(data) {
  return request({
    url: '/oss/file/record',
    method: 'post',
    data: data
  })
}
